exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-innovations-js": () => import("./../../../src/templates/innovations.js" /* webpackChunkName: "component---src-templates-innovations-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-products-item-js": () => import("./../../../src/templates/products-item.js" /* webpackChunkName: "component---src-templates-products-item-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-products-parent-js": () => import("./../../../src/templates/products-parent.js" /* webpackChunkName: "component---src-templates-products-parent-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

